<template>
  <pre v-html="resultText"></pre>
</template>

<script>
export default {
  name: "text-highlighter",
  props: ["type", "text"],
  data() {
    return {
      resultText: "",
      hashtagLinks: {
        default: "https://www.google.com/search?q=#",
        twitter: "https://www.twitter.com/hashtag/",
        facebook: "https://www.facebook.com/hashtag/",
        instagram: "https://www.instagram.com/explore/tags/",
      },
      mentionLinks: {
        default: "https://www.google.com/search?q=@",
        twitter: "https://www.twitter.com/",
        facebook: "https://www.facebook.com/",
        instagram: "https://www.instagram.com/",
      },
    };
  },
  watch: {
    text: {
      handler() {
        if (this.text) {
          this.resultText = this.text
            .replace(
              /#(\w+)/g,
              `<a href="${this.getLink(
                "hashtag",
                this.type
              )}$1" target="_blank" class="text-highlight">#$1</a>`
            )
            .replace(
              /@(\w+)/g,
              `<a href="${this.getLink(
                "mention",
                this.type
              )}$1" target="_blank" class="text-highlight">@$1</a>`
            );
        }
      },
      immediate: true,
    },
  },
  methods: {
    getLink(type, source) {
      if (type == "hashtag") {
        return this.hashtagLinks[source]
          ? this.hashtagLinks[source]
          : this.hashtagLinks.default;
      } else if (type == "mention") {
        return this.mentionLinks[source]
          ? this.mentionLinks[source]
          : this.mentionLinks.default;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
pre {
  font-size: inherit;
  font-family: inherit;
  white-space: pre-wrap;
}
</style>
