<template>
  <div id="social">
    <spinner v-if="isLoading" />
    <div
      v-if="cases.length < 1"
      class="
        small
        text-muted
        font-italic
        p-3
        w-100
        h-100
        d-flex
        justify-content-center
        align-items-center
      "
    >
      No social media post found.
    </div>
    <div class="h-100 row row-cols-1 row-cols-sm-2 g-4" v-else>
      <div
        class="col overflow-auto h-100 social-card"
        v-for="c in cases"
        :key="`socialCard${c.type}`"
        :class="{ hide: currentType != c.type }"
      >
        <div class="card bg-dark shadow-sm">
          <div class="card-header bg-dark text-uppercase sticky-top">
            <div class="card-title">
              <i
                :class="`me-2 
                ${Helper.socialMediaIcons(c.type).i}
                text-${Helper.socialMediaIcons(c.type).color}`"
              />
              {{ Helper.titleCase(c.type) }} ({{ c.data.length }})
            </div>
            <button
              class="btn bg-default-dark d-inline-block d-sm-none"
              @click="switchType(c.type)"
            >
              <i
                :class="`
                ${
                  Helper.socialMediaIcons(
                    c.type == 'twitter' ? 'facebook' : 'twitter'
                  ).i
                }
                text-${
                  Helper.socialMediaIcons(
                    c.type == 'twitter' ? 'facebook' : 'twitter'
                  ).color
                }`"
              />
            </button>
          </div>
          <div
            class="card-body"
            v-for="post in c.data"
            :key="`socialPost${c.type}${post._id}`"
          >
            <div class="d-flex justify-content-between align-items-start">
              <div class="media flex-fill me-2">
                <img
                  :src="post.socmedAvatarUrl"
                  class="me-3 media-image"
                  v-if="post.socmedAvatarUrl"
                  @error="onImageError(post, $event)"
                />
                <img
                  src="@/assets/icons/user.jpg"
                  class="me-2 media-image"
                  v-else
                />
                <div class="media-body">
                  <p class="media-title mb-0">
                    {{ post.socmedUsername }}
                  </p>
                  <div>
                    <p
                      class="text-muted small mb-2 d-inline-block"
                      :title="getMoment('title', post.timestamp)"
                    >
                      {{ getMoment("display", post.timestamp) }}
                    </p>
                  </div>
                </div>
              </div>
              <button
                class="btn btn-primary btn-sm rounded-pill text-nowrap"
                @click="viewInSocialMedia(post)"
              >
                <i
                  :class="`fa-sm me-2 ${
                    Helper.socialMediaIcons(c.type).i
                  } text-light`"
                />View
              </button>
            </div>
            <p class="mt-3">
              <text-highlighter :type="c.type" :text="post.caption" />
            </p>
            <div
              class="p-1 pb-4 mt-3 w-100 clickable"
              v-for="postMedia in post.media"
              :key="postMedia._id"
              :id="`dashboardMedia${postMedia._id}`"
              @click="currentImage = postMedia"
            >
              <div
                class="
                  py-3
                  w-100
                  d-flex
                  justify-content-center
                  align-items-center
                  text-muted
                "
              >
                <span
                  class="spinner-border spinner-border-sm me-2"
                  role="status"
                >
                </span>
                Loading media...
              </div>
              {{ loadImage(postMedia) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <media-preview
      v-if="currentImage"
      :url="Helper.formatMediaUrl(currentImage)"
      @close="currentImage = null"
    />
  </div>
</template>

<script>
import Moment from "moment";
import Spinner from "@/components/Spinner";
import MediaPreview from "@/components/MediaPreview";
import TextHighlighter from "@/components/TextHighlighter";

export default {
  components: {
    Spinner,
    MediaPreview,
    TextHighlighter,
  },
  data() {
    return {
      isLoading: false,
      currentImage: null,
      currentType: "twitter",
      cases: [],
    };
  },
  methods: {
    switchType(type) {
      this.isLoading = true;

      this.$nextTick(() => {
        this.currentType = type == "twitter" ? "facebook" : "twitter";

        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      });
    },
    getMoment(type, d) {
      if (!d) {
        return "-";
      } else {
        switch (type) {
          case "title":
            return Moment(d).format("DD MMMM YYYY, hh:mm A");
          case "display":
            return Moment(d).fromNow(false);
          default:
            return "";
        }
      }
    },
    viewInSocialMedia(url) {
      window.open(url.socmedPostUrl, "_blank");
    },
    loadImage(cm) {
      let img = cm.mime.includes("image")
        ? new Image()
        : document.createElement("VIDEO");

      img.className = "w-100 rounded shadow";

      if (cm.mime.includes("video")) {
        let source = document.createElement("source");

        img.onloadeddata = () => {
          let parent = document.getElementById(`dashboardMedia${cm._id}`);
          if (parent) {
            parent.innerHTML = "";
            parent.appendChild(img);
          }
        };

        source.type = cm.mime;

        source.src = this.Helper.formatMediaUrl(cm);

        img.setAttribute("controls", "controls");

        img.appendChild(source);
      } else {
        img.onload = () => {
          let parent = document.getElementById(`dashboardMedia${cm._id}`);

          if (parent) {
            parent.innerHTML = "";
            parent.appendChild(img);
          }
        };

        img.src = this.Helper.formatMediaUrl(cm);
      }
    },
    onImageError(cl, event) {
      let image = event.target;

      image.onerror = null;

      image.src = require("@/assets/icons/user.jpg");
    },
    getCases() {
      this.isLoading = true;

      this.API.get(
        "cases?timestamp_null=false&_sort=timestamp:DESC&_limit=-1&source=socialMedia&type_in=twitter&type_in=facebook&complaintType_ne=sand_mining"
      )
        .then((retVal) => {
          this.cases = [];

          let rawCases = retVal.data;

          if (rawCases.length > 0) {
            rawCases.forEach((rc) => {
              let rcType = rc.type;

              let caseType = this.cases.find((c) => c.type == rcType);

              if (!caseType) {
                this.cases.push({ type: rcType, data: [rc] });
              } else {
                caseType.data.push(rc);
              }
            });
          }

          this.isLoading = false;
        })
        .catch((err) => {
          console.error(err);

          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.getCases();
  },
};
</script>