<template>
  <div class="media-preview">
    <div class="media-header">
      <div class="d-flex justify-content-start align-items-center">
        <div>
          <h1
            class="d-flex justify-content-start align-items-center text-white"
          >
            {{ title }}
          </h1>
          <h3 class="text-secondary">
            {{ subtitle }}
          </h3>
        </div>
      </div>
      <div>
        <button class="btn-close" @click="closePreview"></button>
      </div>
    </div>
    <div class="media-container p-0 bg-dashboard-dark-1">
      <div class="placeholder" v-if="isLoading">
        <div class="spinner-border text-primary me-3"></div>
        <span class="text-muted">Loading preview...</span>
      </div>
      <div id="osd" :class="{ invisible: isLoading }">
        <div class="osd-buttons">
          <button
            class="btn border border-secondary shadow-sm"
            id="osd-zoom-in"
          >
            <i class="fas fa-plus" />
          </button>
          <button
            class="btn border border-secondary shadow-sm"
            id="osd-zoom-out"
          >
            <i class="fas fa-minus" />
          </button>
          <button class="btn border border-secondary shadow-sm" id="osd-home">
            <i class="fas fa-home" />
          </button>
          <button
            class="btn border border-secondary shadow-sm"
            id="osd-full-page"
          >
            <i class="fas fa-expand" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OSD from "openseadragon";

export default {
  name: "media-preview",
  props: ["url", "title", "subtitle"],
  data() {
    return {
      osd: null,
      isLoading: false,
    };
  },
  methods: {
    closePreview() {
      if (!this.isLoading) {
        this.$nextTick(() => {
          this.$emit("close");
        });
      }
    },
  },
  mounted() {
    this.isLoading = true;

    this.osd = OSD({
      id: "osd",
      prefixUrl: "/osd/",
      crossOriginPolicy: "Anonymous",
      maxZoomPixelRatio: 10,
      navigationControlAnchor: OSD.ControlAnchor.ABSOLUTE,
      zoomInButton: "osd-zoom-in",
      zoomOutButton: "osd-zoom-out",
      homeButton: "osd-home",
      fullPageButton: "osd-full-page",
    }).open({
      type: "image",
      url: `${this.url}`,
    });

    this.osd.addHandler("open", (res) => {
      this.isLoading = false;
    });
  },
  beforeDestroy() {
    this.closePreview();
  },
};
</script>